import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Lipoliza iniekcyjna, bezinwazyjne usuwanie tkanki tłuszczowej`}
        description={`Wykonujemy lipolizę iniekcyjną usuwając tłuszcz z wybranych partii ciała. Redukujemy komórki tłuszczowe w okolicach talii, ud, podbródka. Szczegóły na stronie.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">
                Lipoliza iniekcyjna - zabieg miejscowego usuwania tłuszczu
              </h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Lipoliza iniekcyjna jest zabiegiem mającym na celu trwałe
                usunięcie nadmiaru tkanki tłuszczowej z wybranych części ciała.
                Procedura polega na wstrzyknięciu preparatu, zazwyczaj roztworu
                kwasu połączonego z wyciągami roślinnymi, w ściśle określone
                miejsce. Substancja rozpuszcza komórki tłuszczowe, których
                zawartość zostaje następnie przetransportowana przez układ
                krwionośny do wątroby, a potem wydalona z organizmu. Zabieg
                przynosi dodatkowe efekty w postaci działania liftingującego i
                stymulującego skórę oraz poprawiającego mikrokrążenie.
              </p>

              <p className="offer-item-description">
                Lipoliza iniekcyjna jest stosowana do wyszczuplania okolic
                talii, bioder, ud, jak również twarzy – podbródka czy policzków.
                Znajduje zastosowanie w tych partiach ciała, które szczególnie
                trudno zmodyfikować za pomocą diety albo ćwiczeń. Należy
                pamiętać, że zabieg lipolizy, pomimo wielu zalet, nie jest
                metodą odchudzania, nie zastąpi prawidłowego bilansu
                kalorycznego ani aktywności fizycznej, pozostaje jedynie
                sposobem modelowania sylwetki i pozwala pozbyć się uciążliwych
                fałdek.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Zalety lipolizy iniekcyjnej</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Lipoliza iniekcyjna jako technika redukcji komórek tłuszczowych
                posiada wiele zalet. Przede wszystkim jest to zabieg
                przeprowadzany bez skalpela, nieoperacyjny, charakteryzujący się
                małą inwazyjnością. Klient po wyjściu z gabinetu może powrócić
                do normalnego trybu życia, nie przechodzi długiej
                rekonwalescencji. Efekty widoczne są bardzo szybko i utrzymują
                się na trwałe. Niektóre preparaty stosowane w lipolizie
                odznaczają się przedłużonym działaniem, przez co proces
                wyszczuplania przebiega jeszcze łagodniej, a skutki uboczne
                zostają niemal całkowicie zniwelowane.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
